import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpRequest, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { buildRequest, API } from './api';
import { BillboardDocument } from '../models/billboad-document';

@Injectable({
    providedIn: 'root'
})
export class BillboardManagementService {

    constructor(private http: HttpClient) { }

    uploadDocument(file: File, document: BillboardDocument): Observable<any> {
        let formData: FormData = new FormData();
        formData.append("document", JSON.stringify(document))
        formData.append("file", file, file.name);

        let url = API.BILLBOARD_UPLOAD;
        let req = new HttpRequest("PUT", buildRequest(url, { ":idFile": file.name }), formData, {
            reportProgress: true
        });

        let progress = new Subject<number>();
        /*this.http.request(req).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round((100 * event.loaded) / event.total);
                progress.next(percentDone);
            } else if (event instanceof HttpResponse) {
                progress.complete();
            }
        });

        return progress.asObservable();*/

        return this.http.request(req);
    }

    async getDocumentList(): Promise<BillboardDocument[]> {
        let url = API.BILLBOARD_DOCLIST;
        return await this.http.get<BillboardDocument[]>(url).toPromise();
    }

    async getDocumentListByUser(userId: string): Promise<BillboardDocument[]> {
        let url = buildRequest(API.BILLBOARD_DOCLIST_BYUSER,
            {
                ':userId': userId
            });
        return await this.http.get<BillboardDocument[]>(url).toPromise();
    }

    async deleteDocument(id: string): Promise<any> {
        let url = buildRequest(API.BILLBOARD_DOC,
            {
                ':id': id
            });
        return await this.http.delete<any>(url).toPromise();
    }

    downloadDocument(id: string): Observable<any> {
        let url = buildRequest(API.BILLBOARD_DOC,
            {
                ':id': id
            });
        return this.http.get(url, { responseType: "arraybuffer" });
    }
}
