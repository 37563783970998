import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderBarAction } from 'src/app/components/header-bar/header-bar.component';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ChangePasswordDialogComponent } from 'src/app/dialogs/change-password-dialog/change-password-dialog.component';
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component';
import { AdminUserListComponent, UserAction, Action } from 'src/app/components/admin-user-list/admin-user-list.component';
import { User } from 'src/app/models/user';

@Component({
    selector: 'app-admin-page',
    templateUrl: './admin-page.component.html',
    styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
    showCatalog: boolean;
    showUser: boolean;
    userList: User[];

    constructor(private router: Router,
        private userManagementService: UserManagementService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar) {
        this.showCatalog = false;
        this.showUser = true;

    }

    async ngOnInit() {
        await this.RefreshUserList();
    }

    async onHeaderBarAction(action: HeaderBarAction) {
        switch (action) {
            case HeaderBarAction.AdminCatalog: {
                this.showCatalog = true;
                this.showUser = false;

                break;
            }

            case HeaderBarAction.AdminUsers: {
                this.showCatalog = false;
                this.showUser = true;

                break;
            }

            case HeaderBarAction.AddUser: {
                await this.DoAddUser();
                break;
            }

            case HeaderBarAction.ChangePassword: {
                await this.DoChangePassword();
                break;
            }

            case HeaderBarAction.Logout: {
                await this.DoLogout();
                break;
            }
        }

    }

    private async RefreshUserList() {
        this.userList = await this.userManagementService.userList();
    }

    private async DoLogout() {
        let reply = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Logout', 'Are you sure ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (reply) {
            this.userManagementService.logout();
            this.router.navigate(['user/login']);
        }
    }

    private async DoChangePassword() {
        await this.dialog.open(ChangePasswordDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container'
        }).afterClosed().toPromise();
    }

    private async OnUserActionEvent(userAction: UserAction) {
        if (userAction.action == Action.Delete) {
            let res = await this.dialog.open(ConfirmDialogComponent, {
                width: '400px',
                panelClass: 'custom-dialog-container',
                data: new ConfirmDialogData('Delete User', 'Are you sure  ?', 'YES', 'NO')
            }).afterClosed().toPromise();
            if (res) {
                let res = await this.userManagementService.deleteUser(userAction.user._id);
                if (res.result)
                    await this.RefreshUserList();
                else
                    this.snackBar.open('Error: ' + res.message, 'OK', { duration: 3000 });
            }
        }
    }

    private async DoAddUser() {
        let res = await this.dialog.open(AddUserDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container'
        }).afterClosed().toPromise();
        if (res) {
            await this.RefreshUserList();
        }
    }
}
