import { Component, OnInit } from '@angular/core';
import { UserManagementService } from 'src/app/services/user-management.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfile } from 'src/app/models/logged-user';


// Login in test
// user: admin@mail.com
// password: sysadm1234

@Component({
    selector: 'app-user-login-page',
    templateUrl: './user-login-page.component.html',
    styleUrls: ['./user-login-page.component.scss']
})
export class UserLoginPageComponent implements OnInit {
    registerAdmin: boolean;
    email: string;
    password: string;

    adminName: string;
    adminSurname: string;
    adminEmail: string;
    adminPassword: string;

    constructor(private router: Router,
        private snackBar: MatSnackBar,
        private userManagementService: UserManagementService) {

    }

    async ngOnInit() {
        let users = await this.userManagementService.userList();
        this.registerAdmin = users.length == 0;
    }

    async onLogin() {
        try {
            let ret = await this.userManagementService.login(this.email, this.password);
            if (ret.result == true) { /*
                if (ret.user.profile == UserProfile.Admin)
                    this.router.navigate(['admin']);
                else
                    this.router.navigate(['viewer']); */
                this.router.navigate(['main-sidenav']);
            } else {
                this.snackBar.open('Login failed: ' + ret.reason, 'OK', { duration: 3000 });
            }
        } catch (ex) {
            this.snackBar.open('Login failed: error connecting server', 'OK', { duration: 3000 });
        }
    }

    async onRegisterAdmin() {
        let res = await this.userManagementService.registerUser(this.adminName, this.adminSurname, '', UserProfile.Admin, this.adminEmail, this.adminPassword);
        if (res.result) {
            this.snackBar.open('Administration user successfully created', 'OK', { duration: 3000 });
            this.registerAdmin = false;
        } else {
            this.snackBar.open('Adminnistration user creation failed: ' + res.reason, 'OK', { duration: 3000 });
        }
    }
}
