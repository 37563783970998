import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoginPageComponent } from '../pages/user-login-page/user-login-page.component';
import { AdminPageComponent } from '../pages/admin-page/admin-page.component';
import { AuthGuard } from './auth.guard';
import { ViewerPageComponent } from '../pages/viewer-page/viewer-page.component';
import { MainPageComponent } from '../pages/main-page/main-page.component';
import { AdminUserListComponent } from '../components/admin-user-list/admin-user-list.component';
import { WarehouseItemListComponent } from '../components/warehouse-item-list/warehouse-item-list.component';
import { FileBillboardComponent } from '../components/file-billboard/file-billboard.component';
import { FileBillboardCardsComponent } from '../components/file-billboard-cards/file-billboard-cards.component';
import { MainSidenavPageComponent } from '../pages/main-sidenav-page/main-sidenav-page.component';
import { PricelistItemListComponent } from '../components/pricelist-item-list/pricelist-item-list.component';

const routes: Routes = [
    // { path: 'viewer', component: ViewerPageComponent, canActivate: [AuthGuard] },
    // { path: 'admin', component: AdminPageComponent, canActivate: [AuthGuard] },
    {
        /*
        path: 'main', 
        component: MainPageComponent, 
        */
        path: 'main-sidenav',
        component: MainSidenavPageComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'users', component: AdminUserListComponent },
            { path: 'warehouse', component: WarehouseItemListComponent },
            { path: 'billboard', component: FileBillboardComponent },
            { path: 'pricelist', component: PricelistItemListComponent }
        ]
    },
    { path: 'user/login', component: UserLoginPageComponent },
    { path: '', component: UserLoginPageComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})

export class AppRoutingModule { }