import { Injectable } from '@angular/core';
import { API, buildRequest } from './api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResourceLoader } from '@angular/compiler';
import { LoggedUser, UserProfile } from '../models/logged-user';
import { User } from '../models/user';

export class Response {
    result: boolean;
    reason: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {
    private loggedUser: LoggedUser;

    constructor(private http: HttpClient) { }

    getToken() {
        if (!this.loggedUser)
            return null;

        return this.loggedUser.token;
    }

    getLoggedUser() {
        return this.loggedUser;
    }

    async getUserInfo(userId: string): Promise<User> {
        let url = buildRequest(API.USER_INFO,
            {
                ':id': userId
            });
        return await this.http.get<User>(url).toPromise();
    }


    async registerUser(name: string, surname: string, company: string, profile: UserProfile, email: string, password: string): Promise<Response> {
        let bodyRequest = {
            name: name,
            surname: surname,
            company: company,
            profile: profile,
            email: email,
            password: password
        };
        return this.http.post<Response>(API.USER_REGISTER, bodyRequest).toPromise();
    }

    async login(email: string, password: string) {
        let bodyRequest = {
            email: email,
            password: password
        };
        let ret = await this.http.post<any>(API.LOGIN, bodyRequest).toPromise();
        if (ret.result == true) {
            this.loggedUser = ret.user;
        }

        return ret;
    }

    async changePassword(email: string, oldPassword: string, newPassword: string) {
        let bodyRequest = {
            email: email,
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        let ret = await this.http.post<any>(API.CHANGE_PASSWORD, bodyRequest).toPromise();
        return ret;
    }

    logout() {
        this.loggedUser = null;
    }

    async userList(): Promise<User[]> {
        return await this.http.get<User[]>(API.USER_LIST).toPromise();
    }

    async deleteUser(userId: string): Promise<any> {
        let url = buildRequest(API.USER_DELETE,
            {
                ':id': userId
            });
        return await this.http.delete<any>(url).toPromise();
    }

    async updateUser(userUpdated: User): Promise<any> {
        let bodyRequest = userUpdated;

        let ret = await this.http.put<any>(API.USER_UPDATE, bodyRequest).toPromise();
        return ret;
    }
}
