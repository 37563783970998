import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WarehouseItem } from 'src/app/models/warehouse-item';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserManagementService } from 'src/app/services/user-management.service';
import { WarehouseManagementService } from 'src/app/services/warehouse-management.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfile } from 'src/app/models/logged-user';
import { UploadDialogComponent, UploadSource } from 'src/app/dialogs/upload-dialog/upload-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ItemInspectDialogComponent } from 'src/app/dialogs/item-inspect-dialog/item-inspect-dialog.component';
import { PricelistItem } from 'src/app/models/pricelist-item';

@Component({
  selector: 'app-pricelist-item-list',
  templateUrl: './pricelist-item-list.component.html',
  styleUrls: ['./pricelist-item-list.component.scss']
})
export class PricelistItemListComponent implements OnInit , AfterViewInit {
    @Input() showCommands: boolean;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    pricelistSourceItems = new MatTableDataSource<PricelistItem>();
    displayedColumns = [
        'Id',
        'Description',
        'Family',
        'LabOffice',
        'PriceGroup',
        'ListPrice',
        'Discount',
        'Action'
    ];

    showLoader: boolean;
    pricelistItems: PricelistItem[];

    constructor(private snackBar: MatSnackBar,
        private userManagementService: UserManagementService,
        private warehouseManagementService: WarehouseManagementService,
        private dialog: MatDialog) {
        this.showLoader = true;
        this.pricelistSourceItems = new MatTableDataSource<PricelistItem>();
    }

    async ngOnInit() {
        let loggedUser = this.userManagementService.getLoggedUser();
        this.showCommands = (loggedUser.profile == UserProfile.Admin);
    }

    async ngAfterViewInit() {
        await this.loadAndDisplayTableData();
    }

    async loadAndDisplayTableData() {
        console.log('loadAndDisplayTableData');
        
        this.showLoader = true;

        this.pricelistItems = await this.warehouseManagementService.itemPriceList();
        this.displayTableData(this.pricelistItems);

        this.showLoader = false;
    }

    displayTableData(pricelistItems: PricelistItem[]) {
        let pageItems = pricelistItems.slice(0, 10);
        this.pricelistSourceItems.data = pageItems;

        this.paginator.length = pricelistItems.length;
        this.paginator.page.subscribe((args) => {
            console.log('paginator page: ', args);

            let startPage = args.pageIndex * 10;
            let pageItems = pricelistItems.slice(startPage, startPage + 10);

            this.pricelistSourceItems.data = pageItems;
        });
    }

    applyFilter(filterValue: string) {
        // this.warehouseSourceItems.filter = filterValue; // this.dataSource.filter = filterValue.trim().toLowerCase();
        let filteredItems = this.pricelistItems;
        if (filterValue && (filterValue.length > 2)) {
            filteredItems = this.pricelistItems.filter(data => PricelistItem.filterMatch(data, filterValue))
        }

        this.displayTableData(filteredItems);
    }

    async onUploadPricelist() {
        let res = await this.dialog.open(UploadDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: UploadSource.Pricelist
        }).afterClosed().toPromise();
        if (res) {
            await this.loadAndDisplayTableData();
        }
    }

    async onRemovePricelist() {
        let res = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Remove Pricelist', 'Are you sure  ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (res) {
            await this.warehouseManagementService.clearPricelist();
            await this.loadAndDisplayTableData();
        }

    }

    async onInspectItem(item: WarehouseItem) {
        let reply = await this.dialog.open(ItemInspectDialogComponent, {
            width: '600px',
            panelClass: 'custom-dialog-container',
            data: item
        }).afterClosed().toPromise();

    }
}
