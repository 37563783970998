import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarehouseItem } from 'src/app/models/warehouse-item';
import { WarehouseManagementService } from 'src/app/services/warehouse-management.service';
import { PricelistItem } from 'src/app/models/pricelist-item';
import { MatTableDataSource } from '@angular/material/table';

class WarehouseField {
    Field: string;
    Value: string;
}

@Component({
    selector: 'app-item-inspect-dialog',
    templateUrl: './item-inspect-dialog.component.html',
    styleUrls: ['./item-inspect-dialog.component.scss']
})
export class ItemInspectDialogComponent implements OnInit {
    pricelistItem: PricelistItem;
    warehouseItem: WarehouseItem;

    warehouseFields = new MatTableDataSource<WarehouseField>();
    displayedColumns = [
        'Field',
        'Value',
    ];

    constructor(public dialogRef: MatDialogRef<ItemInspectDialogComponent>,
        private warehouseManagementService: WarehouseManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.pricelistItem = data;
    }

    async ngOnInit() {
        // valid item: 8008079
        this.warehouseItem = await this.warehouseManagementService.getItem(this.pricelistItem.Id);

        console.log('warehouseItem', this.warehouseItem);
        if (this.warehouseItem)
            this.updateTable();
    }

    updateTable() {
        let values: WarehouseField[] = [
            { Field: "Product Id", Value: this.warehouseItem.productId },
            { Field: "Product Name", Value: this.warehouseItem.productName },
            { Field: "Stock Volume", Value: '' + this.warehouseItem.stockVolume },
            { Field: "Committed Inventory", Value: '' + this.warehouseItem.committedInventory},
            { Field: "Qty in Production", Value: '' + this.warehouseItem.quantityInProduction},
            { Field: "Min. Quantity", Value: '' + this.warehouseItem.minimumQuantity },
            { Field: "Mh Stock Volume", Value: '' + this.warehouseItem.mhStockVolume },
            { Field: "Product Group", Value: this.warehouseItem.productGroup },
            { Field: "Price Group", Value: this.warehouseItem.priceGroup },
            { Field: "Restocking Time", Value: this.warehouseItem.restockingTime }/*,
            { Field: "LastCost", Value: '€ ' + this.warehouseItem.LastCost }*/
        ];

        this.warehouseFields.data = values;
    }

    onCloseClick() {
        this.dialogRef.close();
    }

    onOpenLink(item: WarehouseItem) {
        window.open(item.internetUrl, "_blank");
    }
} 