import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export enum HeaderBarAction {
    ChangePassword,
    AdminCatalog,
    AdminUsers,
    Pricelist,
    AddUser,
    Billboard,
    Logout
}

@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
    headerBarAction = HeaderBarAction;

    @Input() ShowAdminCatalog: boolean = false;
    @Input() ShowAdminUser: boolean = false;
    @Input() ShowAddUser: boolean = false;
    @Input() ShowChangePassword: boolean = false;
    @Input() ShowLogout: boolean = false;

    @Output() ActionClickEvent: EventEmitter<HeaderBarAction> = new EventEmitter();

    constructor() { }

    ngOnInit() {

    }

    onActionClick(action: HeaderBarAction) {
        this.ActionClickEvent.emit(action);
    }
}
