import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BillboardDocument } from 'src/app/models/billboad-document';
import { BillboardManagementService } from 'src/app/services/billboard-management.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserProfile } from 'src/app/models/logged-user';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-file-billboard-cards',
    templateUrl: './file-billboard-cards.component.html',
    styleUrls: ['./file-billboard-cards.component.scss']
})
export class FileBillboardCardsComponent implements OnInit {
    @ViewChild('file', { static: false }) file: ElementRef;

    uploadingFile: boolean;
    uploadingFileName: string;
    uploadProgress: number;
    showDeleteDocument: boolean;
    billboardFiles: BillboardDocument[] = [];

    constructor(private billboardManagementService: BillboardManagementService,
        private userManagementService: UserManagementService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog) { }

    async ngOnInit() {
        let loggedUser = this.userManagementService.getLoggedUser();

        this.showDeleteDocument = (loggedUser.profile == UserProfile.Admin);
        await this.updateBillboardFiles();
    }

    async updateBillboardFiles() {
        let documents = await this.billboardManagementService.getDocumentList();
        this.billboardFiles = documents;
    }

    async onUploadFile() {
        console.log(this.file.nativeElement.files[0]);
        let fileChoosen = this.file.nativeElement.files[0];
        if (!fileChoosen)
            return;

        if (fileChoosen.size > (10 * 1024 * 1024)) {
            this.snackBar.open('File size too big. Please upload smaller file.', 'OK', { duration: 2000 });
            return;
        }

        let fileItem = {
            name: fileChoosen.name,
            type: fileChoosen.type,
            size: fileChoosen.size
        };

        this.uploadingFile = true;
        this.uploadingFileName = fileItem.name;
        this.billboardManagementService.uploadDocument(this.file.nativeElement.files[0], null)
            .subscribe(async progress => {
                console.log(progress);
                if (progress == 100) {
                    this.uploadingFile = false;
                    this.uploadingFileName = null;

                    await this.updateBillboardFiles();
                }
            });
    }

    async onDownloadFile(document: BillboardDocument) {
        let result = await this.billboardManagementService.downloadDocument(document._id).toPromise();

        let blob = new Blob([result]);
        let url = window.URL.createObjectURL(blob);
        saveAs(blob, document.file);
    }

    async onDeleteClick(document: BillboardDocument) {
        let res = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Delete Document', 'Are you sure  ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (res) {
            let res = await this.billboardManagementService.deleteDocument(document._id);
            if (res.result)
                await this.updateBillboardFiles();
            else
                this.snackBar.open('Error: ' + res.message, 'OK', { duration: 3000 });
        }
    }

    onUploadClick() {
        this.file.nativeElement.click();
    }
}
