import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WarehouseManagementService } from 'src/app/services/warehouse-management.service';

export enum UploadSource {
    Catalog,
    Pricelist
}

@Component({
    selector: 'app-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
    @ViewChild('file', { static: true }) file: ElementRef;

    uploadingFileName: string;
    uploadProgress: number;
    uploadingFile: boolean;
    uploadingComplete: boolean;

    uploadSource: UploadSource;

    constructor(public dialogRef: MatDialogRef<UploadDialogComponent>,
        private warehouseManagementService: WarehouseManagementService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: UploadSource) {
        this.uploadSource = data;
    }

    ngOnInit() {
    }

    onCloseClick() {
        this.dialogRef.close(false);
    }

    onUploadClick() {
        this.file.nativeElement.click();
    }

    async onUploadFile() {
        console.log(this.file.nativeElement.files[0]);
        let fileChoosen = this.file.nativeElement.files[0];
        if (!fileChoosen)
            return;

        if (fileChoosen.size > (10 * 1024 * 1024)) {
            this.snackBar.open('File size too big. Please upload smaller file.', 'OK', { duration: 2000 });
            return;
        }

        let fileItem = {
            name: fileChoosen.name,
            type: fileChoosen.type,
            size: fileChoosen.size
        };

        this.uploadingFile = true;
        this.uploadingComplete = false;
        this.uploadingFileName = fileItem.name;

        let file = this.file.nativeElement.files[0];

        if (this.uploadSource == UploadSource.Catalog) {
            await this.warehouseManagementService.uploadCatalog(file);
        } else {
            await this.warehouseManagementService.uploadPricelist(file);
        }

        this.uploadingFile = false;
        this.uploadingComplete = true;
        this.uploadingFileName = null;

        console.log('game over');

        this.dialogRef.close(true);
    }
}
