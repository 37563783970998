export class BillboardDocument {
    _id: string;
    name: string;
    file: string;
    title: string;
    description: string;
    createdAt: Date;
    distributionUsers: string[];

    constructor() {

    }

    static Empty(): BillboardDocument {
        let document: BillboardDocument = new BillboardDocument();
        
        document._id = '';
        document.name = '';
        document. file = '';
        document.title = '';
        document.description = '';
        document.createdAt = new Date();
        document.distributionUsers = [];
        
        return document;
    }
}