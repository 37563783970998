import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './core/app-routing.module';
import { MaterialDesignModule } from './core/material.design';
import { JwtInterceptor } from './core/jwt-interceptor';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserLoginPageComponent } from './pages/user-login-page/user-login-page.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { AdminUserListComponent } from './components/admin-user-list/admin-user-list.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { ViewerPageComponent } from './pages/viewer-page/viewer-page.component';
import { WarehouseItemListComponent } from './components/warehouse-item-list/warehouse-item-list.component';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { ItemInspectDialogComponent } from './dialogs/item-inspect-dialog/item-inspect-dialog.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { FileBillboardComponent } from './components/file-billboard/file-billboard.component';
import { FileBillboardCardsComponent } from './components/file-billboard-cards/file-billboard-cards.component';
import { HeaderLogoBarComponent } from './components/header-logo-bar/header-logo-bar.component';
import { MainSidenavPageComponent } from './pages/main-sidenav-page/main-sidenav-page.component';
import { UploadDocumentDialogComponent } from './dialogs/upload-document-dialog/upload-document-dialog.component';
import { PricelistItemListComponent } from './components/pricelist-item-list/pricelist-item-list.component';


@NgModule({
    declarations: [
        AppComponent,
        UserLoginPageComponent,
        AdminPageComponent,
        HeaderBarComponent,
        ConfirmDialogComponent,
        ChangePasswordDialogComponent,
        AdminUserListComponent,
        AddUserDialogComponent,
        MessageDialogComponent,
        ViewerPageComponent,
        WarehouseItemListComponent,
        UploadDialogComponent,
        ItemInspectDialogComponent,
        MainPageComponent,
        FileBillboardComponent,
        FileBillboardCardsComponent,
        HeaderLogoBarComponent,
        MainSidenavPageComponent,
        UploadDocumentDialogComponent,
        PricelistItemListComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialDesignModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    ],
    entryComponents: [
        ConfirmDialogComponent,
        ChangePasswordDialogComponent,
        MessageDialogComponent,
        AddUserDialogComponent,
        UploadDialogComponent,
        ItemInspectDialogComponent,
        UploadDocumentDialogComponent
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
