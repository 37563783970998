import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LoggedUser, UserProfile } from 'src/app/models/logged-user';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderBarAction } from 'src/app/components/header-bar/header-bar.component';
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ChangePasswordDialogComponent } from 'src/app/dialogs/change-password-dialog/change-password-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-main-sidenav-page',
    templateUrl: './main-sidenav-page.component.html',
    styleUrls: ['./main-sidenav-page.component.scss']
})
export class MainSidenavPageComponent implements OnInit {
    @ViewChild('snav', { static: false }) sideNav: MatSidenav;

    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    loggedUser: LoggedUser;

    navigationItems: any[];

    constructor(private router: Router,
        private userManagementService: UserManagementService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        changeDetectorRef: ChangeDetectorRef, 
        media: MediaMatcher) {

        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit() {
        this.loggedUser = this.userManagementService.getLoggedUser();
        
        if (this.loggedUser.profile == UserProfile.Admin)
        {
            this.navigationItems = [
                {
                    icon: './assets/images/book-open-page-variant.svg',
                    action: HeaderBarAction.Pricelist,
                    caption: 'Pricelist'
                },
                {
                    icon: './assets/images/warehouse.svg',
                    action: HeaderBarAction.AdminCatalog,
                    caption: 'Warehouse'
                },
                {
                    icon: './assets/images/account-group.svg',
                    action: HeaderBarAction.AdminUsers,
                    caption: 'Users'
                },
                {
                    icon: './assets/images/billboard.svg',
                    action: HeaderBarAction.Billboard,
                    caption: 'Documents'
                },
                {
                    icon: './assets/images/textbox-password.svg',
                    action: HeaderBarAction.ChangePassword,
                    caption: 'Change Password'
                },
                {
                    icon: './assets/images/logout.svg',
                    action: HeaderBarAction.Logout,
                    caption: 'Logout'
                }

            ];
            this.router.navigate(['main-sidenav/pricelist']);
        } else {
            this.navigationItems = [
                {
                    icon: './assets/images/book-open-page-variant.svg',
                    action: HeaderBarAction.Pricelist,
                    caption: 'Pricelist'
                },
                {
                    icon: './assets/images/warehouse.svg',
                    action: HeaderBarAction.AdminCatalog,
                    caption: 'Warehouse'
                },
                {
                    icon: './assets/images/billboard.svg',
                    action: HeaderBarAction.Billboard,
                    caption: 'Documents'
                },
                {
                    icon: './assets/images/textbox-password.svg',
                    action: HeaderBarAction.ChangePassword,
                    caption: 'Change Password'
                },
                {
                    icon: './assets/images/logout.svg',
                    action: HeaderBarAction.Logout,
                    caption: 'Logout'
                }

            ];

            this.router.navigate(['main-sidenav/pricelist']);
        }

    }

    async onHeaderBarAction(action: HeaderBarAction) {
        switch (action) {
            case HeaderBarAction.AdminCatalog: {
                this.router.navigate(['main-sidenav/warehouse']);
                break;
            }

            case HeaderBarAction.AdminUsers: {
                this.router.navigate(['main-sidenav/users']);
                break;
            }

            case HeaderBarAction.Pricelist: {
                this.router.navigate(['main-sidenav/pricelist']);
                break;
            }

            case HeaderBarAction.Billboard: {
                this.router.navigate(['main-sidenav/billboard']);
                break;
            }

            case HeaderBarAction.ChangePassword: {
                await this.DoChangePassword();
                break;
            }

            case HeaderBarAction.Logout: {
                await this.DoLogout();
                break;
            }
        }
        this.sideNav.close();
    }

    private async DoLogout() {
        let reply = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Logout', 'Are you sure ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (reply) {
            this.userManagementService.logout();
            this.router.navigate(['user/login']);
        }
    }

    private async DoChangePassword() {
        await this.dialog.open(ChangePasswordDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container'
        }).afterClosed().toPromise();
    }


}
