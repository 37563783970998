import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { async } from 'rxjs/internal/scheduler/async';
import { HeaderBarAction } from 'src/app/components/header-bar/header-bar.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ChangePasswordDialogComponent } from 'src/app/dialogs/change-password-dialog/change-password-dialog.component';


@Component({
    selector: 'app-viewer-page',
    templateUrl: './viewer-page.component.html',
    styleUrls: ['./viewer-page.component.scss']
})
export class ViewerPageComponent implements OnInit {
    constructor(private router: Router,
        private userManagementService: UserManagementService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar) {

    }

    ngOnInit() {
    }
    
    async onHeaderBarAction(action: HeaderBarAction) {
        switch (action) {
            case HeaderBarAction.ChangePassword: {
                await this.DoChangePassword();
                break;
            }

            case HeaderBarAction.Logout: {
                await this.DoLogout();
                break;
            }
        }

    }

    private async DoLogout() {
        let reply = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Logout', 'Are you sure ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (reply) {
            this.userManagementService.logout();
            this.router.navigate(['user/login']);
        }
    }

    private async DoChangePassword() {
        await this.dialog.open(ChangePasswordDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container'
        }).afterClosed().toPromise();
    }

}
