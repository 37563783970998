import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BillboardDocument } from 'src/app/models/billboad-document';
import { MatDialogRef } from '@angular/material/dialog';
import { BillboardManagementService } from 'src/app/services/billboard-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { UserManagementService } from 'src/app/services/user-management.service';

@Component({
    selector: 'app-upload-document-dialog',
    templateUrl: './upload-document-dialog.component.html',
    styleUrls: ['./upload-document-dialog.component.scss']
})
export class UploadDocumentDialogComponent implements OnInit {
    @ViewChild('file', { static: false }) file: ElementRef;

    uploadFormGroup: FormGroup;
    document: BillboardDocument;
    uploading: boolean;
    users: User[];

    constructor(public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
        private userManagementService: UserManagementService,
        private billboardManagementService: BillboardManagementService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer) {

        this.document = BillboardDocument.Empty();

        this.uploadFormGroup = this.formBuilder.group({
            title: ["", Validators.required],
            description: ["", Validators.required]
        });
    }


    async ngOnInit() {
        this.uploading = false;
        this.users = await this.userManagementService.userList();
    }

    async onUploadFile() {
        console.log(this.file.nativeElement.files[0]);
        let fileChoosen = this.file.nativeElement.files[0];
        if (!fileChoosen)
            return;

        try {
            this.uploading = true;
            let res = this.billboardManagementService.uploadDocument(fileChoosen, this.document)
                .subscribe(event => {
                    if (event instanceof HttpResponse) {
                        this.uploading = false;
                        this.dialogRef.close();
                    }
                }, error => {
                    this.uploading = false;
                    this.snackBar.open("Error uploading file.", 'OK', { duration: 3000 });
                });
            console.log(res);
            
        } catch (ex) {
            console.log(ex);
            this.snackBar.open("Error uploading file.", 'OK', { duration: 3000 });
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

    onUpload() {
        if (this.uploadFormGroup.invalid) {
            this.uploadFormGroup.controls['title'].markAsTouched();
            this.uploadFormGroup.controls['description'].markAsTouched();
            return;
        }

        this.file.nativeElement.click();
    }

}
