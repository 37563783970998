import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggedUser } from 'src/app/models/logged-user';
import { User } from 'src/app/models/user';

@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
    oldPassword: string = '';
    newPassword: string = '';
    newPasswordConfirm: string = '';

    hideOldPassword: boolean = true;
    hideNewPassword: boolean = true;
    hideNewPasswordConfirm: boolean = true;

    constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private userManagementService: UserManagementService,
        private snackBar: MatSnackBar) {

    }

    ngOnInit() {

    }

    onCancelClick() {
        this.dialogRef.close(false);
    }

    async onConfirmClick() {
        if (this.newPassword != this.newPasswordConfirm) {
            this.snackBar.open("New password mismatch", 'OK', { duration: 2000 });
            return;
        }
        let loggedUser: LoggedUser = this.userManagementService.getLoggedUser();
        let user: User = await this.userManagementService.getUserInfo(loggedUser.id);

        let res = await this.userManagementService.changePassword(user.email, this.oldPassword, this.newPassword);
        if (!res.result) {
            this.snackBar.open(res.reason, 'OK', { duration: 2000 });
            return;
        }
        this.snackBar.open(res.reason, 'OK', { duration: 2000 });
        this.dialogRef.close(true);
    }
}
