import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserManagementService } from '../services/user-management.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userManagementService: UserManagementService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let token = this.userManagementService.getToken();
        if (token == null) {
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}