import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { WarehouseItem } from 'src/app/models/warehouse-item';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent, UploadSource } from 'src/app/dialogs/upload-dialog/upload-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { WarehouseManagementService } from 'src/app/services/warehouse-management.service';
import { DataRowOutlet } from '@angular/cdk/table';
import { ItemInspectDialogComponent } from 'src/app/dialogs/item-inspect-dialog/item-inspect-dialog.component';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UserProfile } from 'src/app/models/logged-user';

@Component({
    selector: 'app-warehouse-item-list',
    templateUrl: './warehouse-item-list.component.html',
    styleUrls: ['./warehouse-item-list.component.scss']
})
export class WarehouseItemListComponent implements OnInit, AfterViewInit {
    @Input() showCommands: boolean;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    warehouseSourceItems = new MatTableDataSource<WarehouseItem>();
    displayedColumns = [
        /*
        'Item',
        'Description',
        // 'CommodityC',
        // 'ItemType',
        'FinalOnHand',
        'ReservedSaleOrd',
        'OrderedPurchOrd',
        'LastReceiptDate',
        'LastIssueDate',
        'FiscalYear',
        // 'LastCost',
        // 'Action'
        */

        'productId', 
        'productName', 
        'stockVolume',
        'committedInventory',
        'quantityInProduction',
        'minimumQuantity',
        'mhStockVolume',
        'productGroup',
        'priceGroup',
        'restockingTime',
        'returnable',
        'Action'
        // 'internetUrl',
    ];

    columnMapping = [
        { name: 'productId', title: 'ID' },
        { name: 'productName', title: 'Name' },
        { name: 'stockVolume', title: 'Stock Volume' },
        { name: 'committedInventory', title: 'Committed Inventory' },
        { name: 'quantityInProduction', title: 'Qty in Production' },
        { name: 'minimumQuantity', title: 'Min. Qty' },
        { name: 'mhStockVolume', title: 'MH Stock Volume' },
        { name: 'productGroup', title: 'Product Group' },
        { name: 'priceGroup', title: 'Price Group' },
        { name: 'restockingTime', title: 'Restock Time' },
        { name: 'returnable', title: 'Returnable' },
        // { name: 'internetUrl', title: 'URL' }
    ];
    showLoader: boolean;
    warehouseItems: WarehouseItem[];

    constructor(private snackBar: MatSnackBar,
        private userManagementService: UserManagementService,
        private warehouseManagementService: WarehouseManagementService,
        private dialog: MatDialog) {
        this.showLoader = true;
        this.warehouseSourceItems = new MatTableDataSource<WarehouseItem>();
    }

    async ngOnInit() {
        let loggedUser = this.userManagementService.getLoggedUser();
        this.showCommands = (loggedUser.profile == UserProfile.Admin);
    }

    async ngAfterViewInit() {
        await this.loadAndDisplayTableData();
    }

    async loadAndDisplayTableData() {
        console.log('loadAndDisplayTableData');
        
        this.showLoader = true;

        this.warehouseItems = await this.warehouseManagementService.itemList();
        this.displayTableData(this.warehouseItems);

        this.showLoader = false;
    }

    displayTableData(warehouseItems: WarehouseItem[]) {
        let pageItems = warehouseItems.slice(0, 10);
        this.warehouseSourceItems.data = pageItems;


        this.paginator.length = warehouseItems.length;
        this.paginator.page.subscribe((args) => {
            console.log('paginator page: ', args);

            let startPage = args.pageIndex * 10;
            let pageItems = warehouseItems.slice(startPage, startPage + 10);

            this.warehouseSourceItems.data = pageItems;
        });
    }

    applyFilter(filterValue: string) {
        // this.warehouseSourceItems.filter = filterValue; // this.dataSource.filter = filterValue.trim().toLowerCase();
        let filteredItems = this.warehouseItems;
        if (filterValue && (filterValue.length > 2)) {
            filteredItems = this.warehouseItems.filter(data => WarehouseItem.filterMatch(data, filterValue))
        }

        this.displayTableData(filteredItems);
    }

    async onUploadCatalog() {
        let res = await this.dialog.open(UploadDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: UploadSource.Catalog
        }).afterClosed().toPromise();
        if (res) {
            await this.loadAndDisplayTableData();
        }
    }

    async onRemoveCatalog() {
        let res = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Remove Catalog', 'Are you sure  ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (res) {
            await this.warehouseManagementService.clearCatalog();
            await this.loadAndDisplayTableData();
        }

    }

    async onInspectItem(item: WarehouseItem) {
        let reply = await this.dialog.open(ItemInspectDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: {}
        }).afterClosed().toPromise();

    }

    async onNavigateURL(item: WarehouseItem) {
        window.open(item.internetUrl, "_blank");
    }

    getItemValue(column: string, item: WarehouseItem) {
        return item[column];
    }
}
