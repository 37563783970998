export class PricelistItem {
    Id: string;
    Description: string;
    Family: string;
    LabOffice: string;
    PriceGroup: string;
    ListPrice: string;
    Discount: string;

    static filterMatch(data: PricelistItem, filter: string): boolean {
        let lowerFilter = filter.toLocaleLowerCase();
        return (data.Id && data.Id.toLocaleLowerCase().includes(lowerFilter)) ||
            (data.Description && data.Description.toLocaleLowerCase().includes(lowerFilter)) ||
            (data.PriceGroup && data.PriceGroup.toLocaleLowerCase().includes(lowerFilter)) ||
            (data.Family && data.Family.toLocaleLowerCase().includes(lowerFilter));
    }
}
