import { environment } from '../../environments/environment';

export const API = {
    // USER MANAGEMENT API
    USER_REGISTER: environment.api_host + "/user/register",
    LOGIN: environment.api_host + "/user/login",
    USER_LIST: environment.api_host + "/user/list",
    USER_DELETE: environment.api_host + "/user/:id",
    USER_INFO: environment.api_host + "/user/:id",
    CHANGE_PASSWORD: environment.api_host + "/user/change-password",
    USER_UPDATE: environment.api_host + "/user",

    //WAREHOUSE MANAGEMENT API
    WAREHOUSE_ITEM_LIST: environment.api_host + "/warehouse/list",
    WAREHOUSE_UPLOAD_CATALOG: environment.api_host + "/warehouse/upload/catalog/:idFile",
    WAREHOUSE_CLEAR_CATALOG: environment.api_host + "/warehouse/clear/catalog",
    WAREHOUSE_GET_ITEM: environment.api_host + "/warehouse/item/:code",

    //WAREHOUSE PRICELIST MANAGEMENT API
    PRICELIST_ITEM_LIST: environment.api_host + "/pricelist/list",
    PRICELIST_UPLOAD_CATALOG: environment.api_host + "/pricelist/upload/catalog/:idFile",
    PRICELIST_CLEAR_CATALOG: environment.api_host + "/pricelist/clear/catalog",

    //BILLBOARD MANAGEMENT API
    BILLBOARD_UPLOAD: environment.api_host + "/billboard/upload/:idFile",
    BILLBOARD_DOCLIST: environment.api_host + "/billboard/documents",
    BILLBOARD_DOCLIST_BYUSER: environment.api_host + "/billboard/documents/:userId",
    BILLBOARD_DOC: environment.api_host + "/billboard/document/:id"
};

export let buildRequest = (string, params) =>
    string.split("/").map(el => {
        return params[el] ? params[el] : el
    }).join("/");