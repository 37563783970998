import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class MessageDialogData {
    title: string;
    message: string;
    confirmButtonText: string;

    constructor(title, message, confirmButtonText) {
        this.title = title;
        this.message = message;
        this.confirmButtonText = confirmButtonText;
    }
}

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
    title: string;
    message: string;
    confirmButtonText: string;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MessageDialogData) {
        this.title = data.title;
        this.message = data.message;
        this.confirmButtonText = data.confirmButtonText;
    }

    ngOnInit() {

    }

    onConfirmClick() {
        this.dialogRef.close(true);
    }

}
