export class WarehouseItem {
    /*
        Item: string;
        Description: string;
        CommodityC: string;
        ItemType: string;
        FinalOnHand: string;
        ReservedSaleOrd: string;
        OrderedPurchOrd: string;
        Colonna1: string
        // LastReceiptDate: { type: Date },
        // LastIssueDate: { type: Date, default: Date.now },
        // FiscalYear: { type: Date, default: Date.now },
        LastReceiptDate: string;
        LastIssueDate: string;
        FiscalYear: string;
        LastCost: string;
    */
    productId: string;
    productName: string;
    stockVolume: number;
    committedInventory: number;
    quantityInProduction: number;
    minimumQuantity: number;
    mhStockVolume: number;
    productGroup: string;
    priceGroup: string;
    internetUrl: string;
    restockingTime: string;
    returnable: string;

    static filterMatch(data: WarehouseItem, filter: string): boolean {
        let lowerFilter = filter.toLocaleLowerCase();
        return (data.productId && data.productId.toLocaleLowerCase().includes(lowerFilter)) ||
            (data.productName && data.productName.toLocaleLowerCase().includes(lowerFilter)) ||
            (data.productGroup && data.productGroup.toLocaleLowerCase().includes(lowerFilter));
    }
}
