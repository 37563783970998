import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/models/user';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component';

export enum Action {
    Delete = 0
}

export class UserAction {
    action: Action;
    user: User;

    constructor(action: Action, user: User){
        this.action = action;
        this.user = user;
    }
}

@Component({
    selector: 'app-admin-user-list',
    templateUrl: './admin-user-list.component.html',
    styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {
    @Input() set UserList(users: User[]) {
        this.userSourceItems = new MatTableDataSource<User>(Array.from(users));
    }

    @Output() userActionEvent = new EventEmitter<UserAction>();

    userSourceItems = new MatTableDataSource<User>();
    displayedColumns = ['Name', /*'Surname', 'Company',*/ 'Profile', 'Email', 'LastLogin', 'LoginCount', 'Action'];

    constructor(private userManagementService: UserManagementService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog) {

    }

    async ngOnInit() {
        await this.loadUserList();
    }

    async loadUserList() {
        let userList: User[] = await this.userManagementService.userList();
        this.userSourceItems = new MatTableDataSource<User>(Array.from(userList));
    }

    async onAddUser() {
        let res = await this.dialog.open(AddUserDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container'
        }).afterClosed().toPromise();
        if (res) {
            await this.loadUserList();
        }
    }

    async onDeleteUser(user: User) {
        // this.userActionEvent.emit(new UserAction(Action.Delete, user));

        let res = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Delete User', 'Are you sure  ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (res) {
            let res = await this.userManagementService.deleteUser(user._id);
            if (res.result)
                await this.loadUserList();
            else
                this.snackBar.open('Error: ' + res.message, 'OK', { duration: 3000 });
        }

        /*
        console.log('onDeleteUser: ', user);
        let reply = await this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: 'custom-dialog-container',
            data: new ConfirmDialogData('Delete User', 'Are you sure ?', 'YES', 'NO')
        }).afterClosed().toPromise();
        if (reply) {
            let res = await this.userManagementService.deleteUser(user._id);
            if (res.result)
                await this.loadUserList();
            else
                this.snackBar.open('Error: ' + res.message, 'OK', { duration: 3000 });
        }
        */
    }

}
