import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { WarehouseItem } from '../models/warehouse-item';
import { API, buildRequest } from './api';
import { Observable, Subject } from 'rxjs';
import { PricelistItem } from '../models/pricelist-item';

@Injectable({
    providedIn: 'root'
})
export class WarehouseManagementService {

    constructor(private http: HttpClient) { }

    itemList(): Promise<WarehouseItem[]> {
        return this.http.get<WarehouseItem[]>(API.WAREHOUSE_ITEM_LIST).toPromise();
    }

    itemPriceList(): Promise<PricelistItem[]> { //TODO
        return this.http.get<PricelistItem[]>(API.PRICELIST_ITEM_LIST).toPromise();
    }

    uploadCatalog(file: File): Promise<any> {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let url = API.WAREHOUSE_UPLOAD_CATALOG;
        let req = new HttpRequest("POST", buildRequest(url, { ":idFile": file.name }), formData, {
            reportProgress: true
        });

        return this.http.request(req).toPromise();
    }

    uploadPricelist(file: File): Promise<any> {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let url = API.PRICELIST_UPLOAD_CATALOG;
        let req = new HttpRequest("POST", buildRequest(url, { ":idFile": file.name }), formData, {
            reportProgress: true
        });

        return this.http.request(req).toPromise();
    }

    clearCatalog() {
        return this.http.get<WarehouseItem[]>(API.WAREHOUSE_CLEAR_CATALOG).toPromise();
    }

    clearPricelist() {
        return this.http.get<WarehouseItem[]>(API.PRICELIST_CLEAR_CATALOG).toPromise();
    }

    getItem(code: string) {
        let url = buildRequest(API.WAREHOUSE_GET_ITEM,
            {
                ':code': code
            });
        return this.http.get<WarehouseItem>(url).toPromise();
    }

}
