import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UserProfile } from 'src/app/models/logged-user';

@Component({
    selector: 'app-add-user-dialog',
    templateUrl: './add-user-dialog.component.html',
    styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
    userProfiles = UserProfile;
    availableProfiles: any;

    name: string;
    surname: string;
    company: string;
    profile: UserProfile;
    email: string;
    password: string;

    registerFormGroup: FormGroup;

    constructor(public dialogRef: MatDialogRef<AddUserDialogComponent>,
        private userManagementService: UserManagementService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder) {
        this.availableProfiles = Object.keys(this.userProfiles);
    }

    ngOnInit() {
        this.registerFormGroup = this.formBuilder.group({
            name: ["", Validators.required],
            surname: ["", Validators.required],
            company: ["", Validators.required],
            profile: ["", Validators.required],
            email: ["", Validators.required],
            password: ["", Validators.required]
        });
    }

    onCancelClick() {
        this.dialogRef.close(false);
    }

    async onAddUserClick() {
        let res = await this.userManagementService.registerUser(this.name, this.surname, this.company, this.profile, this.email, this.password);
        if (!res.result) {
            this.snackBar.open(res.reason, 'OK', { duration: 2000 });
        } else {
            this.dialogRef.close(true);
        }
    }
}
